import { Button, Spinner, Table } from 'react-bootstrap';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import './ConsumptionPage.css';
import { useContext, useEffect, useState } from 'react';
import ProductsContext from '../../context/Products/ProductsContext';
import PopUp from '../../components/PopUp/PopUp';
import axiosClient from '../../settings/axiosClient';
import { getTwoDigits } from '../../helpers/getDayData';
import { getWeekDates } from '../../helpers/getWeekDates';
import { firstUpperCase } from '../../helpers/firstUpperCase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const ConsumptionPage = () => {
  const { stock, getStock, products, getAllProducts } = useContext(ProductsContext);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  //calendar
  const [weekDates, setWeekDates] = useState([]);
  const [firstDateNextWeek, setFirstDateNextWeek] = useState("");
  const [firstDateLastWeek, setFirstDateLastWeek] = useState("");
  const [todayDate, setTodayDate] = useState("");
  const [weekOrders, setWeekOrders] = useState([]);
  const [weekItems, setWeekItems] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [weeklyUsers, setWeeklyUsers] = useState([]);
  const [weeklySales, setWeeklySales] = useState([]);
  const [extra, setExtra] = useState([]); //es el porcentaje al que se vendio sobre el porcentaje al que se deberia haber vendido
  const [informData, setInformData] = useState([]);
  const [informDate, setInformDate] = useState("");

  const handleWeek = (plus) => {
    const weekData = getWeekDates(plus, weekDates, firstDateLastWeek, firstDateNextWeek);
    setWeekDates(weekData.weekDates);
    setFirstDateLastWeek(weekData.firstDateLastWeek);
    setFirstDateNextWeek(weekData.firstDateNextWeek);
  }

  const filterOrders = () => {
    let weekArray = [];
    weekDates.forEach(date => {
      weekArray.push(`${date.split("/")[2]}-${getTwoDigits(Number(date.split("/")[1])+1)}-${getTwoDigits(date.split("/")[0])}`);
    })
    const ordersFiltered = allOrders.filter(order => weekArray.includes(order.createdAt.substring(0, 10)));
    setWeekOrders(ordersFiltered);
    setWeeklyUsers([...new Set(ordersFiltered.map(order => order.owner))]);
    const weeklySalesSup = ordersFiltered.map(order => order.totalPrice).reduce((a, b) => a + b, 0);
    setWeeklySales(weeklySalesSup);
    const actualPrice = ordersFiltered.map(order => order.actualPrice).reduce((a, b) => a + b, 0);
    const extraSup = -1+(weeklySalesSup/actualPrice);
    setExtra((Math.round(extraSup*10000)/100).toFixed(2));
    const weeklyItemsConsumed = ordersFiltered.map(order => order.orderPlaced).map(order => order.map(item => item.itemId)).flat();
    const nonRepeatedItems = [...new Set(weeklyItemsConsumed)];
    const productsArray = nonRepeatedItems.map(item => products.find(product => product._id === item)).sort((a, b) => a.productName.localeCompare(b.productName));
    setWeekItems(productsArray);
    let tableDataArray = [];
    productsArray.map(item => item._id).forEach(item => {
      let itemData = [];
      weekArray.forEach(date => {
        const itemQuantity = ordersFiltered.filter(order => order.createdAt.substring(0, 10) === date).map(order => order.orderPlaced).flat().filter(orderItem => orderItem.itemId === item).reduce((a, b) => a + b.quantity, 0);
        itemData.push(itemQuantity);
      })
      tableDataArray.push(itemData);
    })
    setTableData(tableDataArray);
  }

  const getInform = (from, to) => {
    const informDate = weekDates[to-1]
    setInformDate(`${getTwoDigits(informDate.split("/")[0])}/${getTwoDigits(Number(informDate.split("/")[1])+1)}/${informDate.split("/")[2]}`)
    const informSum = tableData.map(item => item.slice(from, to)).map(item => item.reduce((a, b) => a + b, 0));
    setInformData(informSum.map((item, index) => ({item: weekItems[index].productName, quantity: item, unit: weekItems[index].unit})))
  }

  const getStockFunction = async () => {
    setSpinner(true);
    const getProductsErrors = await getAllProducts();
    if(Object.keys(getProductsErrors). length !== 0) return setErrors(getProductsErrors);
    setSuccess(true);
  }

  const getOrders = async () => {
    if(spinner) return;
    setSpinner(true);
    try {
      const response = await axiosClient.get("/orders");
      if(response.status === 200) {
        const allOrdersComming = response.data.reverse();
        setAllOrders(allOrdersComming);
        setSuccess(true);
      }
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'});
    }
  }

  const popUpBtnFunction = () => {
    setErrorPopUp(false);
    setErrors({});
  }

  useEffect(() => {
    if(products.length === 0) getStockFunction();
    getOrders();
    const weekData = getWeekDates(0, []);
    setWeekDates(weekData.weekDates);
    setFirstDateLastWeek(weekData.firstDateLastWeek);
    setFirstDateNextWeek(weekData.firstDateNextWeek);
    setTodayDate(weekData.todayDate);
  }, [])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
        setErrorPopUp(true);
      }, 1500)
    }
  }, [errors])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
    }
  }, [success])

  useEffect(() => {
    if(allOrders.length !== 0 && weekDates.length !== 0 && products.length !== 0){
      filterOrders();
      setInformData([]);
    };
  }, [allOrders, weekDates, products])

  return (
    <Layout>
      <AdminRoute>
        <div className='consumption-style pt-5'>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          <div className='w-60'>
            <div>Pedidos: <span className='fw-bold'>{weekOrders.length}</span></div>
            <div>Usuarios: <span className='fw-bold'>{weeklyUsers.length}</span></div>
            <div>Ventas: <span className='fw-bold'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(weeklySales))}</span></div>
            <div>Extra: {weeklySales != 0 ? (extra >= 0 ? <span className='text-success'>{extra}%</span> : <span className='text-danger'>{extra}%</span>) : "-"}</div>
          </div>
          <div className='w-100 d-flex justify-content-between mt-3'>
            <Button variant='outline-dark' onClick={() => handleWeek(-1)}><FontAwesomeIcon icon={faAngleLeft} /></Button>
            <Button variant='outline-dark' className='ms-3' onClick={() => handleWeek(1)}><FontAwesomeIcon icon={faAngleRight} /></Button>
          </div>
          <Table striped bordered className="consumptionTable-style rounded mt-2">
            <thead>
              {
                weekDates.length !== 0 && (
                  <tr>
                    <th className='text-center day-w fw-light fst-italic'>Productos / Año {weekDates[0].split("/")[2]}</th>
                    <th className={`text-center day-w fw-light ${todayDate === weekDates[0] ? "text-success fw-bold" : ""}`}>Vi {getTwoDigits(weekDates[0].split('/')[0])}/{getTwoDigits(Number(weekDates[0].split('/')[1])+1)}</th>
                    <th className={`text-center day-w fw-light ${todayDate === weekDates[1] ? "text-success fw-bold" : ""}`}>Sá {getTwoDigits(weekDates[1].split('/')[0])}/{getTwoDigits(Number(weekDates[1].split('/')[1])+1)}</th>
                    <th className={`text-center day-w fw-light ${todayDate === weekDates[2] ? "text-success fw-bold" : ""}`}>Do {getTwoDigits(weekDates[2].split('/')[0])}/{getTwoDigits(Number(weekDates[2].split('/')[1])+1)}</th>
                    <th className={`text-center day-w fw-light ${todayDate === weekDates[3] ? "text-success fw-bold" : ""}`}>Lu {getTwoDigits(weekDates[3].split('/')[0])}/{getTwoDigits(Number(weekDates[3].split('/')[1])+1)}</th>
                    <th className={`text-center day-w fw-light ${todayDate === weekDates[4] ? "text-success fw-bold" : ""}`}>Ma {getTwoDigits(weekDates[4].split('/')[0])}/{getTwoDigits(Number(weekDates[4].split('/')[1])+1)}</th>
                    <th className={`text-center day-w fw-light ${todayDate === weekDates[5] ? "text-success fw-bold" : ""}`}>Mi {getTwoDigits(weekDates[5].split('/')[0])}/{getTwoDigits(Number(weekDates[5].split('/')[1])+1)}</th>
                    <th className={`text-center day-w fw-light ${todayDate === weekDates[6] ? "text-success fw-bold" : ""}`}>Ju {getTwoDigits(weekDates[6].split('/')[0])}/{getTwoDigits(Number(weekDates[6].split('/')[1])+1)}</th>
                    <th className="text-center day-w">TOTAL</th>
                  </tr>
                )
              }
            </thead>
            <tbody>
              {
                weekOrders.length === 0 ? (
                  <tr>
                    <td colSpan="9" className="text-center fw-light fst-italic">NO EXISTEN DATOS CORRESPONDIENTES A ESTA SEMANA</td>
                  </tr>
                ) : (
                  weekItems.map((item, index) => (
                    <tr key={index}>
                      <td className='name-w'>{item?.productName && firstUpperCase(item?.productName)}</td>
                      <td className='text-center'>{Number(tableData[index][0]).toFixed(2)}</td>
                      <td className='text-center'>{Number(tableData[index][1]).toFixed(2)}</td>
                      <td className='text-center'>{Number(tableData[index][2]).toFixed(2)}</td>
                      <td className='text-center'>{Number(tableData[index][3]).toFixed(2)}</td>
                      <td className='text-center'>{Number(tableData[index][4]).toFixed(2)}</td>
                      <td className='text-center'>{Number(tableData[index][5]).toFixed(2)}</td>
                      <td className='text-center'>{Number(tableData[index][6]).toFixed(2)}</td>
                      <td className='text-center'>{Number(tableData[index].reduce((a, b) => a + b, 0)).toFixed(2)}{item?.unit}</td>
                    </tr>
                  ))
                )
              }
              {
                weekOrders.length !== 0 && (
                  <tr>
                    <td className='text-center'></td>
                    <td className='text-center'></td>
                    <td className='text-center'></td>
                    <td className='text-center'><span className='border py-1 px-2 rounded-pill pointer informe-style' onClick={() => getInform(0, 3)}>Informe</span></td>
                    <td className='text-center'></td>
                    <td className='text-center'><span className='border py-1 px-2 rounded-pill pointer informe-style' onClick={() => getInform(3, 5)}>Informe</span></td>
                    <td className='text-center'></td>
                    <td className='text-center'><span className='border py-1 px-2 rounded-pill pointer informe-style' onClick={() => getInform(5, 7)}>Informe</span></td>
                    <td className='text-center'></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
          {
            informData.length !== 0 && (
              <Table striped bordered className="consumptionTable-style rounded mt-2 w-300">
                <thead>
                  <tr>
                    <th className='text-center day-w'>Productos</th>
                    <th className='text-center day-w'>Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='name-w fw-light'>Fecha Informe</td>
                    <td className='text-center day-w fw-light'>{getTwoDigits(new Date().getDate())}/{getTwoDigits(new Date().getMonth()+1)}</td>
                  </tr>
                  <tr>
                    <td className='name-w fw-light'>Hora Informe</td>
                    <td className='text-center day-w fw-light'>{getTwoDigits(new Date().getHours())}:{getTwoDigits(new Date().getMinutes())}</td>
                  </tr>
                  <tr>
                    <td className='name-w fw-bold'>Pedido para:</td>
                    <td className='text-center day-w'>{informDate}</td>
                  </tr>
                  {
                    informData.filter(item => item.quantity !== 0).map((item, index) => (
                      <tr key={index}>
                        <td className='name-w'>{firstUpperCase(item.item)}</td>
                        <td className='text-center day-w'>{Number(item.quantity).toFixed(2)}{item.unit}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            )
          }
        </div>
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={popUpBtnFunction} popUpBtnText={"OK"} noWayOfClose={true} />
      </AdminRoute>
    </Layout>
  );
};

export default ConsumptionPage;