import { useContext, useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import './UpdateProductPage.css';
import axiosClient from '../../settings/axiosClient';
import AdminRoute from '../../routes/AdminRoute';
import ExcelTable from '../../components/ExcelTable/ExcelTable';
import Spinner from '../../components/Spinner/Spinner';
import ProductsContext from '../../context/Products/ProductsContext';
import PopUp from '../../components/PopUp/PopUp';
import UserContext from '../../context/Users/UserContext';
import UpdatedProductDataTable from '../../components/UpdatedProductDataTable/UpdatedProductDataTable';

const UpdateProductPage = () => {
  const [errors, setErrors] = useState({});
  const [excelData, setExcelData] = useState([]);
  const [createTableBoolean, setCreateTableBoolean] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const [updateBtnAppear, setUpdateButtonAppear] = useState(false);
  const { products, getAllProducts, stock, getStock } = useContext(ProductsContext);
  const { admin } = useContext(UserContext);
  const [updatedDataArray, setUpdatedDataArray] = useState([]);
  const [errorPopUp, setErrorPopUp] = useState(false);

  const getProductContext = async () => {
    const getProductsErrors = await getAllProducts();
    if(Object.keys(getProductsErrors).length !== 0){
      setErrors(getProductsErrors)
      return;
    }
    const getStockErrors = await getStock();
    if(Object.keys(getStockErrors).length !== 0){
      setErrors(getStockErrors)
      return;
    }
  }

  const bringData = async () => {
    try {
      const response = await axiosClient.get('/products/marketproducts');
      if(response.status === 200){
        let data = (response.data.sort((a, b) => {
          if (a.productName < b.productName) {
            return -1;
          } else if (a.productName > b.productName) {
            return 1;
          } else {
            return 0;
          }
        }));
        setExcelData(data.map(item => ({
          ...item,
          inStock: stock.find(stockItem => stockItem.itemId === item._id && stockItem.wholeQuantityUpdated > 0) ? "SI" : "NO"
        })));
      }
    } catch (error) {
      setErrors({servidor: 'Error al cargar los Productos. Intente Nuevamente.'});
    }
  }

  const handleUpdate = async () => {
    let updatedData = [];
    let unitChangedStock = [];
    setSpinner(true);
    //Comparo datos con el array de products que no se modifica respecto a la DB
    products.forEach(product => {
      let excelDataProduct = excelData.find(item => item._id === product._id);
      if(excelDataProduct){
        //comparo los datos que pueden llegar a ser modificados unicamente
        if(excelDataProduct.productName !== product.productName || excelDataProduct.image !== product.image || excelDataProduct.unit !== product.unit || excelDataProduct.minUnit !== product.minUnit || excelDataProduct.delta !== product.delta || excelDataProduct.flag2 !== product.flag2 || excelDataProduct.flag1 !== product.flag1 || excelDataProduct.season !== product.season || excelDataProduct.equivalent !== product.equivalent ){
          if(excelDataProduct.unit !== product.unit && excelDataProduct.inStock === "SI"){
            unitChangedStock.push(excelDataProduct.productName);
          }else{
            updatedData.push(excelDataProduct)
          }
        }
      }
    })
    if(unitChangedStock.length !== 0){
      setErrors({stockUnit: `No se pueden modificar las unidades de productos con stock: ${unitChangedStock.join(', ')}.`});
      return;
    }
    if(updatedData.length !== 0) {
      setUpdatedDataArray(updatedData); //guardo en un estado los datos modificados
    }
    setSuccess(true);
  }

  const reestablishData = () => {
    setCreateTableBoolean(false);
    setExcelData([]);
  }

  const errorPopUpFunction = () => {
    setErrorPopUp(false); 
    if(errors.stockUnit) reestablishData();
    setErrors({});
  }

  useEffect(() => {
    if(excelData.length !== 0){
      setTimeout(() => {
        setCreateTableBoolean(true);
        setSpinner(false);
      }, 500)
    }else if(excelData.length === 0 && stock.length !== 0){
      setSpinner(true);
      bringData();
    }
  }, [excelData, stock])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
        setErrorPopUp(true);
      }, 1500)
    }
  }, [errors])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
    }
  }, [success])

  useEffect(() => {
    if(products.length === 0 || stock.length === 0){
      getProductContext();
    }
  }, [])

  return (
    <Layout>
      <AdminRoute>
        <div className='updatePrices-style'>
          <div className={`updateBtnPrices-style bg-light text-center py-2 rounded-pill ${updateBtnAppear && updatedDataArray.length === 0 ? 'updateBtnPrices-style_active' : null}`} onClick={handleUpdate}>Actualizar Datos</div>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          {
            createTableBoolean && updatedDataArray.length === 0 ? (
              <>
                <ExcelTable excelData={excelData} setUpdateButtonAppear={setUpdateButtonAppear} admin={admin} />
              </>
            ) : null
          }
          {
            updatedDataArray.length !== 0 ? (
              <UpdatedProductDataTable updatedDataArray={updatedDataArray} setUpdatedDataArray={setUpdatedDataArray} reestablishData={reestablishData} />
            ) : null
          }
        </div>
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} noWayOfClose={true} popUpBtnFunction={errorPopUpFunction} popUpBtnText={"OK"} />
      </AdminRoute>
    </Layout>
  );
};

export default UpdateProductPage;